import { PageProps, graphql } from 'gatsby';
import { DatoCmsComponentConversionPanel, DatoCmsSeoField, DatoCmsTemplateBlogConnection } from 'graphqlTypes';

import { media } from 'atoms/breakpoints/breakpoints';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Text from 'quarks/Text';

import Section from 'molecules/Section/Section';

import BlogSingleCard from 'components/Cards/BlogSingleCard/BlogSingleCard';
import ComponentButton from 'components/ComponentButton/ComponentButton';
import ConversionPanel from 'components/ConversionPanel/ConversionPanel';

import Layout from 'layouts/Layout';

import type { FC } from 'react';

interface PageType {
  mostRecentData: DatoCmsTemplateBlogConnection;
  conversionData: DatoCmsComponentConversionPanel;
}

const seo404: DatoCmsSeoField = {
  title: 'Not found',
  description: 'Site 404 Page',
} as unknown as DatoCmsSeoField;

const NotFoundPage: FC<PageProps<PageType>> = ({ data: { mostRecentData, conversionData }, location, pageContext }) => (
  <Layout seo={seo404 as DatoCmsSeoField} slug={location.pathname} domain={(pageContext as any)?.domain}>
    <Section
      id="page-404"
      paddingTopDesktop="80"
      paddingTopTablet="80"
      paddingTopMobile="80"
      paddingBottomDesktop="40"
      paddingBottomTablet="40"
      paddingBottomMobile="40"
      isOverSection
      backgroundColor="globe"
      hasSubfix
    >
      <Flex paddingTop={128} flexDirection="column">
        <Container width="100%" md={{ maxWidth: '1008px', marginX: 'auto' }}>
          <Flex flexDirection="column" gap="24px" textAlign="center" marginBottom={32} lg={{ marginBottom: 40 }}>
            <Heading
              as="h1"
              textStyle="xxl"
              textColor="common-white"
              fontWeight="semiBold"
              letterSpacing="neg2"
              css={`
                ${media.md} {
                  font-size: 120px;
                  line-height: 136px;
                }
                ${media.md} {
                  font-size: 160px;
                  line-height: 180px;
                }
                ${media.lg} {
                  font-size: 200px;
                  line-height: 216px;
                }
                background: linear-gradient(95.6deg, #3db5e6 29.1%, #bdecff 81.92%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
              `}
            >
              404
            </Heading>
            <Heading as="h2" textStyle="sm" textColor="gray-50" fontWeight="medium" letterSpacing="neg2">
              Oops! That page doesn’t exist
            </Heading>
            <Text fontSize="textXl" textColor="gray-50">
              Lorem ipsum dolor sit amet consectetur. Dui eget viverra leo lectus. Ut tellus ligula auctor aliquet at
              malesuada pellentesque.
            </Text>
          </Flex>
        </Container>
      </Flex>
    </Section>
    <Section
      id="recent-blog-list"
      paddingTopDesktop="96"
      paddingTopTablet="72"
      paddingTopMobile="48"
      paddingBottomDesktop="96"
      paddingBottomTablet="72"
      paddingBottomMobile="48"
      isOverSection
      backgroundColor="secondaryGrayBlue-25"
    >
      <Heading
        as="h6"
        marginBottom={24}
        fontSize="textMd"
        textColor="secondaryTurqoise-400"
        fontWeight="semiBold"
        textTransform="uppercase"
        letterSpacing="pos8"
      >
        BLOG
      </Heading>
      <Heading as="h3" marginBottom={32} textStyle="lg" textColor="gray-900" fontWeight="semiBold" maxWidth="800px">
        Here are some latest resources to help you <Text textColor="secondaryTurqoise-400">get back on track</Text>
      </Heading>
      <Flex flexWrap="wrap" gap="24px" alignItems="center" marginBottom={64}>
        <ComponentButton
          color="primary_blue_800"
          size="lg"
          link="/blog"
          label="View all blog posts"
          buttonType="fill"
        />
        <ComponentButton
          color="gray_900"
          size="lg"
          link="/blog"
          label="Secondary CTA"
          buttonType="link"
          endIcon="right_arrow"
        />
      </Flex>
      <Flex flexWrap="wrap" justifyContent="center" gap="32px" flexDirection="column" sm={{ flexDirection: 'row' }}>
        {mostRecentData?.edges?.slice(0, 3)?.map((blog, idx) => (
          <BlogSingleCard
            key={blog.node.id}
            {...blog.node}
            width="100%"
            flexGrow={1}
            flexBasis={0}
            sm={{
              flex: '0 0 calc(50% - 16px)',
              width: 'calc(50% - 16px)',
              maxWidth: '380px',
              display: idx === 2 ? 'none' : 'flex',
            }}
            lg={{
              flex: '0 0 calc(33.33% - 22px)',
              width: 'calc(33.33% - 22px)',
              display: 'flex',
            }}
          />
        ))}
      </Flex>
    </Section>
    <Section
      id="recent-blog-list"
      paddingTopDesktop="96"
      paddingTopTablet="72"
      paddingTopMobile="48"
      paddingBottomDesktop="96"
      paddingBottomTablet="72"
      paddingBottomMobile="48"
      isOverSection
      backgroundColor="secondaryGrayBlue-900"
      hasPrefix
      hasSubfix
      isLastSection
    >
      <ConversionPanel {...conversionData} />
    </Section>
  </Layout>
);

export default NotFoundPage;

export const notFoundPageQuery = graphql`
  query template404Query($domain: String!) {
    mostRecentData: allDatoCmsTemplateBlog(
      filter: { domain: { eq: $domain } }
      limit: 3
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          ...datoCmsTemplateBlogList
        }
      }
    }
    conversionData: datoCmsComponentConversionPanel(originalId: { eq: "111860821" }) {
      ...datoCmsComponentConversionPanel
    }
  }
`;
